.App {
  width: 100%;
  min-height: 100vh;
  background-color: #f5f5f5;
}

/* Editor Layout */
.editor-layout {
  display: grid;
  grid-template-columns: 250px 1fr;
  grid-template-rows: 56px auto 1fr;
  min-height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
}

/* Gallery Layout */
.gallery-layout {
  width: 100%;
  min-height: 100vh;
  padding: 2rem;
}

.main-content {
  grid-column: 2;
  grid-row: 3;
  padding: 2rem;
  background-color: #f5f5f5;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 116px); /* 56px toolbar + 60px top bar */
  box-sizing: border-box;
  padding-bottom: var(--main-padding-bottom, 2rem);
  padding-top: var(--main-padding-top, 2rem);

  &::-webkit-scrollbar {
    width: 8px;
  }
  
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;
    
    &:hover {
      background: #bbb;
    }
  }
}

.main-content.editing {
  --main-padding-bottom: 100px;
}

.main-content.with-top-bar {
  --main-padding-top: 8rem;
  height: calc(100vh - 240px);
  padding: 4rem 0.75rem 2rem 0.75rem;
}

.dropzone {
  border-radius: 8px;
  min-height: 500px;
  padding: 1rem;
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: 100px;
  gap: 1rem;
  background: rgba(0, 0, 0, 0.03);
  position: relative;
}

.icon-input, .label-input {
  background: #333;
  border: 1px solid #444;
  color: white;
  padding: 0.25rem;
  margin: 0;
  border-radius: 4px;
  font-size: 0.9rem;
  box-sizing: border-box;
}

.icon-input {
  width: 40px;
  min-width: 40px;
}

.label-input {
  flex: 1;
  min-width: 0;
  width: calc(100% - 50px);
  box-sizing: border-box;
}

.edit-actions {
  display: flex;
  gap: 0.25rem;
  margin-left: auto;
  flex-shrink: 0;
}

.edit-actions button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 0.25rem;
  opacity: 0.7;
  font-size: 1rem;
  border-radius: 4px;
}

.edit-actions button:hover {
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.1);
}

.add-item-button {
  width: 100%;
  padding: 0.75rem;
  background: #333;
  border: none;
  color: white;
  border-radius: 8px;
  margin-top: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.add-item-button:hover {
  background: #444;
}

.add-item-button .material-icons {
  font-size: 1.2rem;
}

.icon-selector {
  position: static;
}

.icon-display {
  display: flex;
  align-items: center;
  background: transparent;
  padding: 0rem;
  border-radius: 4px;
  cursor: pointer;
  min-width: 40px;
  justify-content: space-between;
}

.icon-dropdown {
  position: fixed;
  top: auto;
  left: auto;
  background: #2a2a2a;
  border: 1px solid #444;
  border-radius: 4px;
  padding: 0.5rem;
  z-index: 1201 !important;
  width: 300px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  max-height: 80vh;
  overflow-y: auto;
  transform: translate(0, 5px);
}

.icon-search {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  background: #333;
  border: 1px solid #444;
  color: white;
  border-radius: 4px;
  box-sizing: border-box;
}

.icon-search:focus {
  outline: none;
  border-color: #666;
}

.icon-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 0.5rem;
  max-height: 300px;
  overflow-y: auto;
  background: #333;
  padding: 0.5rem;
  border-radius: 4px;
}

.icon-option {
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.2s;
  text-align: center;
  background: #2a2a2a;
  color: white;
}

.icon-option:hover {
  background: #444;
  transform: scale(1.1);
}

.material-icons {
  font-size: 20px;
}

.sidebar-item .icon {
  margin-right: 0.75rem;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
}

.sidebar-item.selected {
  background-color: #2a2a2a;
  border-left: 4px solid #007bff;
}

.label {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  margin-right: 0.5rem;
}

.dashboard-area {
  position: relative;
  min-height: 500px;
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 8px;
  margin: 1rem;
  overflow: hidden;
}

.add-widget-placeholder {
  background: rgba(255, 255, 255, 0.8) !important;
  border: 2px dashed #ccc;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s;
}

.add-widget-placeholder:hover {
  background: rgba(255, 255, 255, 0.9) !important;
  border-color: #999;
}

.add-widget-icon {
  font-size: 2rem !important;
  color: #999;
  margin-bottom: 0.5rem;
}

.component-list-sidebar {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 300px;
  background: white;
  box-shadow: -2px 0 8px rgba(0,0,0,0.1);
  padding: 1rem;
  z-index: 1200;
  display: flex;
  flex-direction: column;
}

.component-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
  border-bottom: 1px solid #eee;
}

.component-list {
  overflow-y: auto;
  flex: 1;
  padding: 1rem 0;
}

.component-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.2s;
}

.component-item:hover {
  background: #f5f5f5;
}

.configuration-sidebar button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 4px;
  color: #666;
}

.configuration-sidebar button:hover {
  background: #f5f5f5;
  color: #333;
}

.configuration-sidebar h3 {
  margin: 0;
  color: #333;
}

/* Add these styles to handle icon picker z-index and scrolling */
.configuration-sidebar {
  z-index: 1200;
}

.icon-picker-overlay {
  z-index: 1201;
}

/* Prevent body scrolling when icon picker is open */
body.icon-picker-open {
  overflow: hidden;
}

body.dragging {
  cursor: grabbing;
  user-select: none;
}

.top-bar {
  grid-column: 2;
  grid-row: 2;
  background: white;
  height: 60px;
  padding: 0 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 100;
}

.top-bar .logo {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.top-bar .logo h1 {
  margin: 0;
  font-size: 1.5rem;
  color: #333;
}

.top-bar .logo .material-icons {
  font-size: 1.8rem;
  color: #007bff;
}

.top-bar-actions {
  display: flex;
  gap: 1rem;
}

.top-bar-actions button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 50%;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-bar-actions button:hover {
  background: #f5f5f5;
  color: #333;
}

.top-bar-actions .material-icons {
  font-size: 1.5rem;
}

.top-bar-actions .edit-action {
  background: rgba(0, 123, 255, 0.1);
  border: 1px dashed #007bff;
  color: #007bff;
  transition: all 0.2s;
}

.top-bar-actions .edit-action:hover {
  background: rgba(0, 123, 255, 0.2);
  border-style: solid;
}

.top-bar-actions .edit-action .material-icons {
  font-size: 1.2rem;
}

.component-list-sidebar {
  z-index: 1200;
}

.color-picker-popover {
  z-index: 1201;
}

/* AdminToolbar */
.toolbar-banner {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  grid-column: 1 / -1;
  grid-row: 1;
  background: #e0e0e0;
  padding: 0.75rem 2rem;
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  z-index: 1100;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.main-content {
  grid-column: 2;
  grid-row: 2;
  overflow-y: auto;
  height: calc(100vh - 56px);
}

/* When top bar is hidden */
.editor-layout:not(:has(.top-bar)) .main-content {
  grid-row: 2 / span 2;
  height: calc(100vh - 56px); /* Only subtract toolbar height */
}

/* Add these styles to your existing App.css */
.delete-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  flex-direction: column;
  gap: 1rem;
  color: white;
}

.delete-overlay .spinner {
  animation: spin 1s linear infinite;
  font-size: 48px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body.deleting {
  overflow: hidden;
}
